import React, { useState } from "react";
import styles from "./Career.module.css";

const Career = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    city: "",
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "resume" ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form Submitted!");
    console.log(formData);
  };

  return (
    <section className={styles["career-container"]}>
      <h1>Careers</h1>
      <p>
        We are looking for enthusiastic and skilled individuals to join our IT
        team. If you love technology and solving problems, this is the place for
        you! We offer a friendly and creative environment where you can grow
        your skills and advance your career.
      </p>
      <form onSubmit={handleSubmit}>
        <label>
          <span className="required-label">Name</span>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          <span className="required-label">Email Address</span>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          <span className="required-label">Phone</span>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          <span className="required-label">Role</span>
          <select
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="" disabled></option>
            <option value="Front-end Developer">Front-end Developer</option>
            <option value="Back-end Developer">Back-end Developer</option>
            <option value="Full-stack Developer">Full-stack Developer</option>
            <option value="UI/UX Designer">UI/UX Designer</option>
          </select>
        </label>
        <label>
          <span className="required-label">City</span>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          <span className="required-label">Resume</span>
          <input
            type="file"
            name="resume"
            accept=".pdf,.doc,.docx"
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </section>
  );
};

export default Career;
